<template>
  <div
    v-if="Object.keys(user).length"
    id="user-profile"
  >
    <profile-header
      :user="user"
    />
    <b-row>
      <b-col
        cols="12"
        lg="8"
        order="2"
        order-lg="1"
      >
        <b-card>
          <h5 class="text-capitalize mb-75">
            {{ $t('Offers') }}
          </h5>
          <b-card-text>
            <profile-service-offers
              :user="user"
            />
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        lg="4"
        order="1"
        order-lg="2"
      >
        <b-card
          v-if="user.bio"
          class="text-center text-secondary"
        >
          {{ user.bio }}
        </b-card>
        <b-card>
          <h5 class="text-capitalize mb-75">
            {{ $t('Contact') }}
          </h5>
          <b-card-text>
            <profile-phones
              :user="user"
            />
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>

</template>
<script>
import {
  BCard, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import store from '@/store'
import ProfileServiceOffers from '@/views/pages/profile/ProfileServiceOffers.vue'
import ProfilePhones from '@/views/pages/profile/ProfilePhones.vue'
import ProfileHeader from './ProfileHeader.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    ProfileHeader,
    ProfilePhones,
    ProfileServiceOffers,
  },

  data() {
    return {

    }
  },
  setup() {
    const user = ref({})
    const ability = defineAbilityForCurrentUser()
    user.value = store.getters['account/user']

    return {
      user,
      ability,
    }
  },

}

</script>
<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
